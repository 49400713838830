<template>
    <div class="companys">
        <div class="company_top flex">
            <img src="../../assets/images/common/logo.png" alt="">
            <div>
                <p style="font-size: 20px; color: #1991FC;">企业认证</p>
                <div class="mt8" style="font-size: 14px; color: rgba(42, 39, 39, 0.8)"><span class="mr25">买家昵称：{{form.userChineseName}}</span><span>提交时间：{{form.submitTime}}</span></div>
            </div>
            <div style="margin-left: auto">
                <p style="font-size: 18px; color: #1991FC;">待审核</p>
                <div style="font-size: 14px; color: rgba(42, 39, 39, 0.8)">审核次数：{{historyList.length}}</div>
            </div>
        </div>
        <div style="height: 20px; background: #F6F7F9;"></div>
        <div class="company_content">
            <h4 class="mb16" style="color: #1D1E1F; font-size: 14px">企业认证列表</h4>
            <el-tabs v-model="activeName" @tab-click="handleClickTab">
                <el-tab-pane label="本次详情" name="1">
                    <div class="el-icon-s-flag mb14 mt20"><span>主体信息</span></div>
                    <div class="zhuti flex" style="width: 100%">
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">公司名称</div>
                            <div style="width: 60%">{{form.companyName}}</div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">统一社会信用代码</div>
                            <div style="width: 60%">{{form.socialUnifiedIdentityCreditCode}}</div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">执照类型</div>
                            <div style="width: 60%">三证合一</div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">营业执照图片</div>

                            <div style="width: 60%; cursor: pointer; color: #308FE5;">
                                
                                <el-popover
                                    placement="top-start"
                                    width="400"
                                    trigger="click">
                                    <el-image
                                        style="width: 400px;"
                                        :src="form.buslicenseFileBOlist[0].filePath"
                                        :fit="fit"></el-image>
                                    <span slot="reference">点击查看</span>
                                </el-popover>
                                </div>
                        </div>
                    </div>
                    <div class="zhuti flex" style="width: 100%">
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">营业执照所在地</div>
                            <div style="width: 60%">{{form.licenseLocationName}}</div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">注册资本</div>
                            <div style="width: 60%">{{form.registeredCapital}}万元</div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">成立日期</div>
                            <div style="width: 60%">{{form.companyCreateDate}}</div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">营业期限</div>
                            <div style="width: 60%">{{form.businessBeginTerm}}至{{form.businessEndTerm}}</div>
                        </div>
                    </div>
                    <div class="zhuti flex" style="width: 100%">
                        <div class="flex" style="width: 100%;">
                            <div style="width: 10%">住所</div>
                            <div style="width: 90%">{{form.residence}}</div>
                        </div>
                        
                    </div>

                    <div class="zhuti flex" style="width: 100%">
                        <div class="flex" style="width: 100%;">
                            <div style="width: 10%">经营范围</div>
                            <div style="width: 90%">{{form.natureBusiness}}</div>
                        </div>
                        
                    </div>


                    <div class="el-icon-s-flag mb14 mt20"><span>法人信息</span></div>
                    <div class="zhuti flex" style="width: 100%">
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">法定代表人证件类型</div>
                            <div style="width: 60%">中国大陆身份证</div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">法定代表人证件图片</div>
                            <div style="width: 60%; cursor: pointer; color: #308FE5;">
                                
                                <el-popover
                                    placement="top-start"
                                    width="400"
                                    trigger="click">
                                    <el-image
                                        style="width: 400px;"
                                        :src="form.idCardFileBOlist[0].filePath"
                                        :fit="fit"></el-image>
                                    <span slot="reference" style="margin-right: 12px">查看正面</span>
                                </el-popover>

                                <el-popover
                                    placement="top-start"
                                    width="400"
                                    trigger="click">
                                    <el-image
                                        style="width: 400px;"
                                        :src="form.idCardFileBOlist[1].filePath"
                                        :fit="fit"></el-image>
                                    <span slot="reference">查看反面</span>
                                </el-popover>
                                </div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">证件所有人姓名</div>
                            <div style="width: 60%">{{form.credentialsName}}</div>
                        </div>
                        <div class="flex" style="width: 25%;">
                            <div style="width: 40%">证件号码</div>
                            <div style="width: 60%">{{form.credentialsNumber}}</div>
                        </div>
                    </div>
                    
                    <div class="zhuti flex" style="width: 100%">
                        <div class="flex" style="width: 100%;">
                            <div style="width: 10%">证照有效期</div>
                            <div style="width: 90%">{{form.credentialsValidityBeginDate}}至{{form.credentialsValidityEndDate}}</div>
                        </div>
                        
                    </div>


                    <div class="el-icon-s-flag mb14 mt20"><span>联系人信息</span></div>
                    <div class="zhuti flex" style="width: 75%">
                        <div class="flex" style="width: 33.3%;">
                            <div style="width: 40%">联系人姓名</div>
                            <div style="width: 60%">{{form.userChineseName}}</div>
                        </div>
                        <div class="flex" style="width: 33.3%;">
                            <div style="width: 40%">联系人电话</div>
                            <div style="width: 60%">{{form.mobile}}</div>
                        </div>
                        <div class="flex" style="width: 33.3%;">
                            <div style="width: 40%">联系人邮箱</div>
                            <div style="width: 60%">{{form.email}}</div>
                        </div>

                        
                    </div>


                    <div class="el-icon-s-order mb14 mt20"><span>审批信息</span></div>
                    <div class="pl24 pt24 pr24 pb24">
                        <div style="padding: 20px 25px; background: #fafafa">
                            <span @click="chooseQuesion(item,index)" class="reason" :class="item.choose ? 'ischoose' : ''" v-for="(item,index) in spRensonList" :key="index">{{item.name}}</span>
                        </div>
                    </div>

                    <el-form ref="form" label-width="140px">
                        <el-form-item v-for="(item,index) in chooseList" :key="index" :label="item.name">
                            <el-input v-model="item.approveReason"></el-input>
                        </el-form-item>
                    </el-form>

                    <div style="height: 44px"></div>
                   
                </el-tab-pane>
                <el-tab-pane label="历史认证记录" name="2">
                    <div>
                        <el-collapse v-model="activeName1" accordion>
                            <el-collapse-item title="一致性 Consistency" :name="index" v-for="(item,index) in historyList"  :key="index">
                                <template slot="title" class="header">
                                  <span>提交时间：{{item.submitTime}}</span>
                                  <div style="margin-left: auto; color: rgba(0, 0, 0, 0.5)">
                                      <span>审核时间：{{item.approveTime}}</span>
                                      <span class="ml60">审核人：{{item.approveUserName}}</span>
                                  </div>
                                  
                                </template>
                                

                                <div class="pl12 pr12">
                                    <div class="el-icon-s-flag mb14 mt20"><span>主体信息</span></div>
                                    <div class="zhuti flex" style="width: 100%">
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">公司名称</div>
                                            <div style="width: 60%">{{item.companyName}}</div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">统一社会信用代码</div>
                                            <div style="width: 60%">{{item.socialUnifiedIdentityCreditCode}}</div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">执照类型</div>
                                            <div style="width: 60%">三证合一</div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">营业执照图片</div>

                                            <div style="width: 60%; cursor: pointer; color: #308FE5;">
                                                
                                                <el-popover
                                                    placement="top-start"
                                                    width="400"
                                                    trigger="click">
                                                    <el-image
                                                        style="width: 400px;"
                                                        :src="item.buslicenseFileBOlist[0].filePath"
                                                        :fit="fit"></el-image>
                                                    <span slot="reference">点击查看</span>
                                                </el-popover>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="zhuti flex" style="width: 100%">
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">营业执照所在地</div>
                                            <div style="width: 60%">{{item.licenseLocationName}}</div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">注册资本</div>
                                            <div style="width: 60%">{{item.registeredCapital}}万元</div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">成立日期</div>
                                            <div style="width: 60%">{{item.companyCreateDate}}</div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">营业期限</div>
                                            <div style="width: 60%">{{item.businessBeginTerm}}至{{item.businessEndTerm}}</div>
                                        </div>
                                    </div>
                                    <div class="zhuti flex" style="width: 100%">
                                        <div class="flex" style="width: 100%;">
                                            <div style="width: 10%">住所</div>
                                            <div style="width: 90%">{{item.residence}}</div>
                                        </div>
                                        
                                    </div>

                                    <div class="zhuti flex" style="width: 100%">
                                        <div class="flex" style="width: 100%;">
                                            <div style="width: 10%">经营范围</div>
                                            <div style="width: 90%">{{item.natureBusiness}}</div>
                                        </div>
                                        
                                    </div>


                                    <div class="el-icon-s-flag mb14 mt20"><span>法人信息</span></div>
                                    <div class="zhuti flex" style="width: 100%">
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">法定代表人证件类型</div>
                                            <div style="width: 60%">中国大陆身份证</div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">法定代表人证件图片</div>
                                            <div style="width: 60%; cursor: pointer; color: #308FE5;">
                                                
                                                <el-popover
                                                    placement="top-start"
                                                    width="400"
                                                    trigger="click">
                                                    <el-image
                                                        style="width: 400px;"
                                                        :src="item.idCardFileBOlist[0].filePath"
                                                        :fit="fit"></el-image>
                                                    <span slot="reference" style="margin-right: 12px">查看正面</span>
                                                </el-popover>

                                                <el-popover
                                                    placement="top-start"
                                                    width="400"
                                                    trigger="click">
                                                    <el-image
                                                        style="width: 400px;"
                                                        :src="item.idCardFileBOlist[1].filePath"
                                                        :fit="fit"></el-image>
                                                    <span slot="reference">查看反面</span>
                                                </el-popover>
                                                </div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">证件所有人姓名</div>
                                            <div style="width: 60%">{{item.credentialsName}}</div>
                                        </div>
                                        <div class="flex" style="width: 25%;">
                                            <div style="width: 40%">证件号码</div>
                                            <div style="width: 60%">{{item.credentialsNumber}}</div>
                                        </div>
                                    </div>
                                    
                                    <div class="zhuti flex" style="width: 100%">
                                        <div class="flex" style="width: 100%;">
                                            <div style="width: 10%">证照有效期</div>
                                            <div style="width: 90%">{{item.credentialsValidityBeginDate}}至{{item.credentialsValidityEndDate}}</div>
                                        </div>
                                        
                                    </div>


                                    <div class="el-icon-s-flag mb14 mt20"><span>联系人信息</span></div>
                                    <div class="zhuti flex" style="width: 75%">
                                        <div class="flex" style="width: 33.3%;">
                                            <div style="width: 40%">联系人姓名</div>
                                            <div style="width: 60%">{{item.userChineseName}}</div>
                                        </div>
                                        <div class="flex" style="width: 33.3%;">
                                            <div style="width: 40%">联系人电话</div>
                                            <div style="width: 60%">{{item.mobile}}</div>
                                        </div>
                                        <div class="flex" style="width: 33.3%;">
                                            <div style="width: 40%">联系人邮箱</div>
                                            <div style="width: 60%">{{item.email}}</div>
                                        </div>

                                        
                                    </div>
                                </div>
                                
                            </el-collapse-item>
                            
                        </el-collapse>
                    </div>
                </el-tab-pane>
            </el-tabs>

           
        </div>

        <div v-if="activeName == 1" style="text-align: center; line-height: 44px; position: fixed; bottom: 0; height: 44px; background: #fff; box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.06);" :style="{'width': width+'px'}">
             <el-button size="small" @click="goBack">返回</el-button>
             <el-button type="primary" @click="approval(3)" size="small">通过</el-button>
             <el-button type="danger" @click="approval(2)" size="small">拒绝</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            activeName: "1",
            activeName1: "1",
            chooseList: [],
            form: {},
            historyList: [],
            width: 0,
            spRensonList: [{
                approveType: 1,
                name: '公司名称',
                approveReason: '',
                choose: false
            },{
                approveType: 2,
                name: '统一社会信用代码',
                approveReason: '',
                choose: false
            },{
                approveType: 3,
                name: '营业执照图片',
                approveReason: '',
                choose: false
            },{
                approveType: 4,
                name: '营业执照所在地',
                approveReason: '',
                choose: false
            },{
                approveType: 5,
                name: '注册资本',
                approveReason: '',
                choose: false
            },{
                approveType: 6,
                name: '成立日期',
                approveReason: '',
                choose: false
            },{
                approveType: 7,
                name: '营业期限',
                approveReason: '',
                choose: false
            },{
                approveType: 8,
                name: '住所',
                approveReason: '',
                choose: false
            },{
                approveType: 9,
                name: '经营范围',
                approveReason: '',
                choose: false
            },{
                approveType: 10,
                name: '法定代表人证件图片',
                approveReason: '',
                choose: false
            },{
                approveType: 11,
                name: '证件所有人姓名',
                approveReason: '',
                choose: false
            },{
                approveType: 12,
                name: '证件号码',
                approveReason: '',
                choose: false
            },{
                approveType: 13,
                name: '证件有效期',
                approveReason: '',
                choose: false
            }]
        }
    },
    mounted(){
        this.search();
        this.width = document.body.offsetWidth - 206 - 68;
    },
    methods: {
        goBack(){
            window.history.go(-1)
        },
        handleClickTab(e){
            console.log(this.activeName)
        },
        approval(count){
            let params = {
                id: this.$route.query.id,
                status: count,
                userEnterpriseCertificationApproveBOList: []
            }
            for(let i = 0; i < this.chooseList.length; i++) {
                let obj = {};
                obj.approveReason = this.chooseList[i].approveReason;
                obj.approveType = this.chooseList[i].approveType;
                params.userEnterpriseCertificationApproveBOList.push(obj)
                
            }
            this.qa.companyApprovalYesOrNoById(params).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        window.history.go(-1);
                    },1500)
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        search(){
            this.qa.companyApprovalById({id: this.$route.query.id}).then(res => {
                this.form = res.data;
            })

            this.qa.queryCompanyHistory({}).then(res => {
                this.historyList = res.data;
            })
        },
        chooseQuesion(row,i){
            this.$set(this.spRensonList[i], 'choose', !row.choose);
            if(this.spRensonList[i].choose) {
                this.chooseList.push(row);
            } else {
                for(let j = 0; j < this.chooseList.length; j++) {
                    if(this.chooseList[j].approveType == row.approveType) {
                        this.chooseList.splice(j,1)
                    }
                }
            }
            
            
        }
    }
}
</script>

<style scoped>
  .companys >>> .el-collapse-item__header {
      background: #f9f9f9;
      padding: 0 14px;
  }
  .companys >>> .el-collapse-item .is-active {
      background: #f9f9f9 !important;
  }
  .companys >>> .el-collapse-item .el-collapse-item__arrow {
      margin-left: 40px;
  }
  .company_content >>> .el-tabs .is-active {
      background: rgba(25, 145, 252, 0.1);
  }
  .company_content >>> .el-tabs .el-tabs__item {
      /* padding: 0; */
      

  }
  .company_content >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
  }
  .company_content >>> .el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 20px;
  }
  .company_top {
      padding: 10px;
      background: #fff;
      align-items: center;
  }
  .company_top >img {
      width: 76px;
      height: 76px;
  }
  .company_content {
      padding: 20px 28px;
  }
  .zhuti {
      border: 1px solid #E7EBEF;
      height: 44px;
      line-height: 44px;
  }
  .zhuti >div div {
      padding: 0 10px;
      font-size: 12px;
      border-right: 1px solid #E7EBEF;
  }
  .zhuti >div div:first-child {
      background: #F9F9F9;

  }
  .reason {
      color: rgba(25, 145, 252, 0.8);
      padding: 6px 10px;
      border: 1px solid #1991FC;
      border-radius: 18px;
      margin-bottom: 10px;
      margin-right: 12px;
      display: inline-block;
      cursor: pointer;
  }
  .reason:hover {
      background: rgba(221, 236, 249, 0.8);
  }
  .ischoose {
      background: rgba(221, 236, 249, 0.8);
  }
</style>